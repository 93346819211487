// strona główna

import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';

import { useGetLayoutLanguages } from 'api';

import { Modal, Button } from 'components/controls';

import { HtmlBlock } from 'components/containers/HtmlBlock';

import '../../theme/pages/Home/Home.scss';

interface LocationState {
  title?: string;
  message?: string;
}

const Home = () => {
  const { i18n } = useTranslation();
  const supportedLangs = i18n.options.supportedLngs || [];
  const location = useLocation();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const params = qs.parse(location.search.replace('?', ''), { parseBooleans: true });

  const locationState = location.state as LocationState;

  const getPosition = (string: string, subString: string, index: number) => {
    return string.split(subString, index).join(subString).length;
  };

  const urlPrefix = location.pathname
    .slice(0, getPosition(location.pathname, '/', 2))
    .replace('/', '');

  // pobranie listy dostępnych języków
  const { data: languagesData } = useGetLayoutLanguages();

  const title = languagesData?.items.find((language) => language.id === urlPrefix);

  useEffect(() => {
    if (params?.thankYouRegister || params?.registerEmailConfirm) {
      setOpen(true);
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
    navigate('/', { replace: true });
  };

  return (
    <div className="home-page">
      <Helmet>
        <title>{title?.title_website}</title>
        <meta name="description" content={title?.description_website} />
        <link rel="canonical" href={window.location.href} />
        {supportedLangs.map((language, i) => {
          if (language !== 'cimode')
            return (
              <link
                key={i}
                rel="alternate"
                href={`${window.location.origin}/${language}`}
                hrefLang={language}
              />
            );
        })}
      </Helmet>
      <HtmlBlock sectionId="HOME_DESKTOP" />

      {open && (
        <Modal title={locationState?.title || ''} onClose={() => handleClose()}>
          <div
            className="confirmationModalContent"
            dangerouslySetInnerHTML={{ __html: locationState?.message || '' }}></div>
          <div className="confirmationModalActions">
            <Button onClick={() => handleClose()}>Ok</Button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Home;
