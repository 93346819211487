import React, { FC } from 'react';
import classnames from 'classnames';

import { useGetExportDocument } from 'api';
import { IDocumentListItemVmp } from 'api/types';

import { Download } from 'assets/icons';

import styles from 'theme/pages/Documents/Documents.module.scss';

type IProps = {
  item: IDocumentListItemVmp;
};

const DocumentExport: FC<IProps> = ({ item }) => {
  // export zamówienia do pdf
  const { refetch: getExportDocuments } = useGetExportDocument(item.id, {
    enabled: false,
    onSuccess: (data) => {
      const a = document.createElement('a');
      a.download = data.file_name;
      a.href = data.url;
      a.click();
    }
  });

  const handleExport = () => {
    item.has_document_files && getExportDocuments();
  };

  return (
    <span
      onClick={() => handleExport()}
      className={classnames(styles.cell, styles.documentNumber, {
        [styles.hasDocumentsFiles]: !!item.has_document_files
      })}>
      {item.symbol} {item.has_document_files && <Download />}
    </span>
  );
};

export default DocumentExport;
