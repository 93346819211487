// Hook odpowiedzialny za walidację koszyka

import axios from 'api/axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { ICommandResponseError as IError, ICommandResponseSuccess } from 'api/types';

// typ zwracanych danych
type IResponse = ICommandResponseSuccess;

const postClientArchive = (clientId: number): Promise<IResponse> =>
  axios.post(`/clients/${clientId}/archive`);

export const usePostClientArchive = (
  clientId: number,
  options?: UseMutationOptions<IResponse, IError>
) => useMutation(() => postClientArchive(clientId), options);
