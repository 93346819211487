import React, { FC, useState, useMemo } from 'react';
import { Grid } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { format } from 'date-fns';

import { IOrderVmp } from 'api/types';
import { useRWD } from 'hooks';
import { Status, Modal } from 'components/controls';
import { OrderSwitch, ReceiverForm } from 'components/containers';
import { PaymentEdit, DeliveryEdit, CommentEdit, DownloadSection } from './components';

import { CheckIcon } from 'assets/icons';

import styles from 'theme/pages/Order/components/OrderSummary/OrderSummary.module.scss';

interface IProps {
  orderData: IOrderVmp;
  refetchOrderData: () => void;
  isEditingMode: boolean;
}

const OrderSummary: FC<IProps> = ({ orderData, refetchOrderData, isEditingMode }) => {
  const { isMobile } = useRWD();
  const { t } = useTranslation();

  // czy jest otwarty modal zmiany adresu
  const [isModalOpen, setIsModalOpen] = useState(false);

  const receiver = useMemo(
    () => ({
      id: orderData.id,
      name: orderData.receiver_name,
      first_name: orderData.receiver_first_name,
      last_name: orderData.receiver_last_name,
      email: orderData.receiver_email,
      phone: orderData.receiver_phone,
      address: {
        street: orderData.receiver_address.street,
        postal_code: orderData.receiver_address.postal_code,
        city: orderData.receiver_address.city,
        building: orderData.receiver_address.building,
        apartment: orderData.receiver_address.apartment,
        country: orderData.receiver_address.country_code,
        country_code: orderData.receiver_address.country_code
      }
    }),
    [orderData]
  );

  return (
    <div
      className={classnames(
        styles.wrapperComponent,
        'StylePath-Pages-Order-components-OrderSummary'
      )}>
      {isMobile && (
        <div className={classnames(styles.title, styles.mainTitle)}>ID{orderData.id}D</div>
      )}
      <div className={styles.blocks}>
        <Grid container>
          <Grid item xs={12}>
            <div className={styles.titleWrapper}>
              <div className={styles.title}>
                <span>
                  <Trans>Zamówienie</Trans>: {orderData?.id}
                </span>{' '}
                {orderData.dropshipping && (
                  <span>
                    <Trans>Dropshipping</Trans>
                    <CheckIcon />
                  </span>
                )}
              </div>
              <div className={styles.statusWrapper}>
                <div>
                  <Status
                    icon={orderData?.status.icon || ''}
                    message={orderData?.status.label || ''}
                    color={orderData?.status.color}
                  />
                </div>

                <div className={styles.hold}>
                  {orderData.is_blocked ? <Trans>Zablokowane</Trans> : <Trans>Odblokowane</Trans>}

                  <OrderSwitch
                    orderId={orderData.id}
                    isBlocked={orderData.is_blocked}
                    refetch={refetchOrderData}
                    isEditingMode={isEditingMode}
                  />
                </div>
              </div>
            </div>
          </Grid>
          <Grid className={styles.box} item xs={12} lg={4}>
            <div className={styles.boxTitle}>
              <Trans>Dane do faktury</Trans>
            </div>
            <div className={styles.customer}>
              {orderData.client_name}
              <br />
              {orderData.client_first_name} {orderData.client_last_name}
              <br />
              {orderData.client_address?.street} {orderData.client_address?.building}
              {orderData.client_address.apartment && '/'}
              {orderData.client_address?.apartment}
              <br />
              {orderData.client_address?.postal_code} {orderData.client_address?.city}
              <br />
              <br />
              <span className={styles.label}>
                <Trans>NIP</Trans>:
              </span>{' '}
              {orderData.client_tax_number}
              <br />
              <span className={styles.label}>
                <Trans>E-mail</Trans>:
              </span>{' '}
              {orderData.client_email}
              <br />
              <span className={styles.label}>
                <Trans>Telefon</Trans>:
              </span>{' '}
              {orderData.client_phone}
            </div>
          </Grid>
          <Grid className={styles.box} item xs={12} lg={4}>
            <div className={styles.inner}>
              <div className={styles.boxTitle}>
                <Trans>Dane odbiorcy</Trans>
                {isEditingMode && orderData.dropshipping && (
                  <button className={styles.changeBtn} onClick={() => setIsModalOpen(true)}>
                    <Trans>Zmień</Trans>
                  </button>
                )}
              </div>
              <div className={styles.receiver}>
                {orderData?.receiver_name_to_show}
                <br />
                {orderData.receiver_first_name} {orderData.receiver_last_name}
                <br />
                {orderData.receiver_address.street} {orderData.receiver_address.building}
                {orderData.receiver_address.apartment && '/'}
                {orderData.receiver_address.apartment}
                <br />
                {orderData.receiver_address.postal_code} {orderData.receiver_address.city}
                <br />
                <br />
                <span className={styles.label}>
                  <Trans>Email</Trans>:
                </span>{' '}
                {orderData.receiver_email}
                <br />
                <span className={styles.label}>
                  <Trans>Telefon</Trans>:
                </span>{' '}
                {orderData.receiver_phone}
              </div>
            </div>
          </Grid>
          <Grid className={styles.box} item xs={12} lg={4}>
            <div className={styles.inner}>
              <div className={styles.boxTitle}>
                <Trans>Data utworzenia</Trans>
              </div>
              <div>
                {orderData?.create_date
                  ? format(new Date(orderData.create_date), 'dd-MM-yyyy')
                  : '---'}
              </div>
              {!isMobile && <br />}
              <div className={styles.boxTitle}>
                <Trans>Wartość zamówienia</Trans>
              </div>
              <div>
                {orderData.value_gross_formatted.replace('.', ',')} {orderData.currency}{' '}
                <Trans>brutto</Trans>
              </div>
            </div>
          </Grid>
          <DeliveryEdit
            orderData={orderData}
            refetchOrderData={refetchOrderData}
            isEditingMode={isEditingMode}
          />
          {orderData.show_client_note ? (
            <CommentEdit
              orderData={orderData}
              refetchOrderData={refetchOrderData}
              isEditingMode={isEditingMode}
            />
          ) : (
            <Grid className={classnames(styles.box, styles.payment)} item xs={12} lg={4}>
              <div className={styles.inner}>
                <PaymentEdit orderData={orderData} isEditingMode={isEditingMode} />
              </div>
            </Grid>
          )}
          {orderData.is_editable ? (
            <CommentEdit
              orderData={orderData}
              refetchOrderData={refetchOrderData}
              isEditingMode={isEditingMode}
            />
          ) : (
            <DownloadSection orderData={orderData} />
          )}
        </Grid>
      </div>
      {isModalOpen && (
        <Modal title={t('Edytuj dane odbiorcy')} onClose={() => setIsModalOpen(false)}>
          <ReceiverForm
            id={orderData.id}
            receiver={receiver}
            isDropShipping={orderData.dropshipping}
            isEditOrder
            onCancelButtonClick={() => setIsModalOpen(false)}
            refetchOrderData={refetchOrderData}
          />
        </Modal>
      )}
    </div>
  );
};

export default OrderSummary;
