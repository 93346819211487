// listy płatności

import React, { FC } from 'react';
import classnames from 'classnames';
import { Trans } from 'react-i18next';

import { IDashboardSummary } from 'api/types';
import { ClockIcon, AlertIcon } from 'assets/icons';

import styles from 'theme/pages/Dashboard/Dashboard.module.scss';

// typ danych wejściowych
interface IProps {
  expiredPayments?: IDashboardSummary['expired_payments'];
  upcomingPayments?: IDashboardSummary['upcoming_payments'];
}

const Payments: FC<IProps> = ({ upcomingPayments, expiredPayments }) => {
  return (
    <div className={classnames(styles.payments, 'StylePath-Pages-Dashboard-Components-Payments')}>
      <div className={styles.tile}>
        <div className={styles.title}>
          <ClockIcon />
          <Trans>Nadchodzące płatności</Trans>
        </div>
        <div className={styles.row}>
          <span>
            <Trans>Ilość faktur</Trans>
          </span>
          <span>
            <Trans>Suma</Trans>
          </span>
        </div>
        <div className={styles.row}>
          <div>{upcomingPayments?.count}</div>
          <div className={styles.price}>
            {upcomingPayments?.amount_formatted.replace('.', ',')} {upcomingPayments?.currency}{' '}
            <span className={styles.brutto}>
              <Trans>brutto</Trans>
            </span>
          </div>
        </div>
      </div>
      <div className={styles.tile}>
        <div className={styles.title}>
          <AlertIcon />
          <Trans>Po terminie</Trans>
        </div>
        <div className={styles.row}>
          <span>
            <Trans>Ilość faktur</Trans>
          </span>
          <span>
            <Trans>Suma</Trans>
          </span>
        </div>
        <div className={styles.row}>
          <div>{expiredPayments?.count}</div>
          <div className={styles.price}>
            {expiredPayments?.amount_formatted.replace('.', ',')} {expiredPayments?.currency}{' '}
            <span className={styles.brutto}>
              <Trans>brutto</Trans>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payments;
