// panel nad tabelką na stronie koszyka

import React, { FC, useMemo } from 'react';

import { IClientStatusListItem } from 'api/types';
import { usePostClientAccept, usePostClientNew, usePostClientArchive } from 'api';
import { Select } from 'components/controls';

// typ danych wejściowych
interface IProps {
  clientStatuses?: IClientStatusListItem[];
  value: string;
  clientId: number;
  refetchClients: () => void;
}

const SelectStatus: FC<IProps> = ({ clientStatuses, value, clientId, refetchClients }) => {
  const { mutate: acceptClient } = usePostClientAccept(clientId, {
    onSuccess: () => refetchClients()
  });
  const { mutate: newClient } = usePostClientNew(clientId, { onSuccess: () => refetchClients() });
  const { mutate: archiveClient } = usePostClientArchive(clientId, {
    onSuccess: () => refetchClients()
  });

  // opcje dropdownu typów pola
  const selectOptions = useMemo(() => {
    const sections = (clientStatuses || []).map((status) => ({
      value: status.status_symbol,
      label: <span>{status.status_formatted}</span>,
      item: status
    }));

    return sections;
  }, [clientStatuses]);

  const handleClick = (item: IClientStatusListItem) => {
    if (item.status_symbol === 'ARCHIVE') {
      archiveClient();
    }
    if (item.status_symbol === 'NEW') {
      newClient();
    }
    if (item.status_symbol === 'ACTIVE') {
      acceptClient();
    }
  };

  return (
    <Select<IClientStatusListItem>
      options={selectOptions}
      variant="small"
      value={value}
      onChange={(item) => {
        item && handleClick(item);
      }}
    />
  );
};

export default SelectStatus;
