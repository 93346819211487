// główny layout - górna belka nagłówka

import React from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronDown } from 'react-bootstrap-icons';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';
import { Helmet } from 'react-helmet';

import { useGetLayoutLanguages } from 'api';

import { DropDown } from 'components/controls';

import styles from 'theme/components/layouts/MainLayout/components/HeaderTopBar/HeaderTopBar.module.scss';

const HeaderTopBar = () => {
  const { i18n, t } = useTranslation();
  const { pathname } = useLocation();

  const getPosition = (string: string, subString: string, index: number) => {
    return string.split(subString, index).join(subString).length;
  };

  const urlPrefix = pathname.slice(0, getPosition(pathname, '/', 2)).replace('/', '');

  // pobranie listy dostępnych języków
  const { data: languagesData } = useGetLayoutLanguages();

  const title = languagesData?.items.find((language) => language.id === urlPrefix);

  return (
    <div
      className={classnames(
        styles.wrapperComponent,
        styles.languagesWrapper,
        'StylePath-Components-Containers-Languages'
      )}>
      <Helmet>
        <title>{title?.title_website}</title>
        <meta name="description" content={title?.description_website} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <span>{t('Wybierz język')}</span>
      <DropDown
        label={
          <div className={styles.language}>
            <div>
              <span>{i18n.language.toUpperCase()}</span>
            </div>
            <ChevronDown className={styles.arrowDown} />
          </div>
        }
        items={
          languagesData?.items.map((lang) => ({
            label: <span className={styles.language}>{lang.name}</span>,
            onClick: () => i18n.changeLanguage(lang.id)
          })) || []
        }
        withDropdownIcon={false}
      />
    </div>
  );
};

export default HeaderTopBar;
