// Hook odpowiedzialny za pobranie szczegłów produktu

import axios from 'api/axios';
import { useQuery, UseQueryOptions } from 'react-query';
import {
  ICommandResponseError as IError,
  IImage,
  IStock,
  IUnit,
  IProductLabel,
  ITechnicalAttribute
} from 'api/types';

export interface IFile {
  id: number;
  name: string;
  extension: string;
  url: string;
  label: string;
  thumbnail_url: string | null;
}

export interface IColor {
  product_id: number;
  name: string;
  color: string;
}

export interface IVersion {
  product_id: number;
  name: string;
  color: string;
  size: string;
  price_net: number;
  price_net_formatted: string;
  vmp_stock_qty: number;
  partner_stock_qty: number;
  producer_stock_qty: number;
  different_warehouse_prices: boolean;
  partner_price_net: number;
  partner_price_net_formatted: string;
  producer_price_net: number;
  producer_price_net_formatted: string;
  old_price_net: number;
  old_price_net_formatted: string;
  old_partner_price_net: number;
  old_partner_price_net_formatted: string;
  old_producer_price_net: number;
  old_producer_price_net_formatted: string;
}

export interface ILogisticAttribute {
  name: string;
  value: string;
  icon: string;
}

// typ zwracanych danych
export interface IResponse {
  id: number;
  index: string;
  title: string;
  description: string;
  description_full: string;
  description_short: string;
  sizing_table: string;
  producer_name: string;
  producer_id: string;
  stock: IStock;
  default_unit_id: number;
  price_formatted_to_show: string;
  eur_price_formatted_to_show: string;
  old_price_formatted_to_show: string | null;
  price_suggested_formatted_to_show: string;
  price_suggested_old_formatted_to_show: string;
  units: IUnit[];
  files: IFile[];
  images: IImage[];
  labels: IProductLabel[];
  logistic_attributes: ILogisticAttribute[];
  technical_attributes: ITechnicalAttribute[];
  currency: string;
  colors: IColor[];
  versions: IVersion[];
  category_id: number;
  category_name: string;
  different_warehouses_prices: boolean;
  promotion?: {
    color: string;
    name: string;
    filters: string;
  };
  sizes: string[];
  url_link: string;
}

const getProduct = (id: number): Promise<IResponse> => axios.get(`/products/${id}`);

export const useGetProduct = (id: number, options?: UseQueryOptions<IResponse, IError>) =>
  useQuery<IResponse, IError>(['product', id], () => getProduct(id), options);
