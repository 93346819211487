// komponent modal'a

import React, { PropsWithChildren, FC, ReactElement } from 'react';
import MuiModal from '@mui/material/Modal';
import { X } from 'react-bootstrap-icons';
import classnames from 'classnames';

import styles from 'theme/components/controls/Modal/Modal.module.scss';

// typ danych wejściowych
interface IProps {
  title?: string | ReactElement;
  fullScreen?: boolean;
  fullWidth?: boolean;
  isFiltersOverlay?: boolean;
  onClose?: () => void;
  blur?: boolean;
  consent?: boolean;
  disableMaxHeight?: boolean;
  isOverLogin?: boolean;
}

const Modal: FC<PropsWithChildren<IProps>> = ({
  title,
  onClose,
  children,
  fullScreen,
  fullWidth,
  isFiltersOverlay,
  blur,
  consent,
  disableMaxHeight,
  isOverLogin
}) => {
  return (
    <MuiModal
      disableEscapeKeyDown
      open
      className={classnames('StylePath-Components-Controls-Modal')}
      BackdropProps={{ className: classnames({ [styles.blur]: !!blur }) }}>
      <div
        className={classnames('modal', styles.wrapperComponent, {
          [styles.fullScreen]: fullScreen,
          [styles.fullWidth]: fullWidth,
          [styles.isFiltersOverlay]: isFiltersOverlay,
          [styles.consent]: !!consent,
          [styles.disableMaxHeight]: !!disableMaxHeight,
          [styles.isOverLogin]: !!isOverLogin
        })}>
        <div className={styles.header}>
          <div className={styles.title}> {title} </div>
          {!!onClose && (
            <div className={styles.close}>
              <X onClick={onClose} />
            </div>
          )}
        </div>
        <div> {children}</div>
      </div>
    </MuiModal>
  );
};

export default Modal;
