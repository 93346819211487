import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import { ArrowRight } from 'react-bootstrap-icons';

import { Link, Alert } from 'components/controls';
import { useGetCartPaymentsExpired } from 'api';

import { CreditCard } from 'assets/icons';

import styles from 'theme/components/containers/CartsButton/CartsButton.module.scss';

// typ danych wejściowych
interface IProps {
  currentCartId: number;
}

const ExpiredPayments: FC<IProps> = ({ currentCartId }) => {
  // pobranie informacji o przedawnionych płatności - potrzebne do wyświetlenia alertu
  const { data: paymentsExpiredData } = useGetCartPaymentsExpired(currentCartId);

  if (!paymentsExpiredData?.expired_payments_count) {
    return null;
  }

  return (
    <div className={styles.cartAlert}>
      <Alert type="error" icon={<CreditCard />}>
        <div className={styles.messageWrapper}>
          <span>{paymentsExpiredData?.message}</span>
          <Link to="/dashboard/documents" className={styles.link}>
            <Trans>Przejdź do zakładki</Trans> <ArrowRight />
          </Link>
        </div>
      </Alert>
    </div>
  );
};

export default ExpiredPayments;
