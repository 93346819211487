import React, { FC, useEffect, useState } from 'react';

import { useGetDynamicUIPageDefinition } from 'plugins/api/endpoints';
import { IComponentParams, IDynamicUIPageDefinitionResponse } from 'plugins/api/types';
import { useNotifications } from 'hooks';

import { EditPanel } from 'plugins/components';

// typ danych wejściowych
interface IProps {
  pageSymbol: string;
  requestParams: object;
  componentParams?: IComponentParams;
}

const DynamicComponent: FC<IProps> = ({ pageSymbol, requestParams, componentParams }) => {
  const { showSuccessMessage, showErrorMessage } = useNotifications();

  const [pageDefinition, setPageDefinition] = useState<IDynamicUIPageDefinitionResponse | null>(
    null
  );

  // pobieranie definicji komponentów
  const { refetch: getDynamicDefinition } = useGetDynamicUIPageDefinition(
    pageSymbol,
    requestParams,
    {
      enabled: false,
      onSuccess: (data) => {
        if (data?.status === 0) {
          if (data?.show_message && data?.message) {
            if (data?.message_style === 'success') {
              showSuccessMessage(data?.message);

              return;
            }

            if (data?.message_style === 'error') {
              showErrorMessage(data?.message);

              return;
            }

            showErrorMessage(data?.message);
          }

          componentParams?.onErrorCallback();

          return;
        }

        setPageDefinition(data);
      }
    }
  );

  useEffect(() => {
    getDynamicDefinition();
  }, []);

  const renderContent = () => {
    return pageDefinition?.page_definition?.components.map((component, i) => {
      if (component.type === 'editPanel') {
        return (
          <EditPanel
            title={component.label}
            fields={component.fields}
            actions={component.actions}
            key={i}
            componentParams={componentParams}
            requestParams={requestParams}
          />
        );
      }

      return null;
    });
  };

  return <>{renderContent()}</>;
};

export default DynamicComponent;
