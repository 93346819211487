import React, { FC, useState, useEffect } from 'react';

import { IDynamicUiField, IComponentParams, IDynamicAction } from 'plugins/api/types';
import { Modal } from 'components/controls';
import { Action } from 'plugins/components';

import { DynamicSelect } from './components/DynamicSelect';
import { DynamicDecimal } from './components/DynamicDecimal';

import 'plugins/theme/components/EditPanel/EditPanel.scss';

// typ danych wejściowych
interface IProps {
  title: string;
  fields: IDynamicUiField[];
  actions: IDynamicAction[];
  componentParams?: IComponentParams;
  requestParams?: object;
}

const EditPanel: FC<IProps> = ({ title, fields, componentParams, requestParams, actions }) => {
  // czy jest widoczny modal
  const [isVisible, setIsVisible] = useState(componentParams?.isEditorInitialVisible);

  const [requestState, setRequestState] = useState({});

  // ustalenie początkowego stanu requestState
  useEffect(() => {
    fields.map((field) =>
      setRequestState((prevState: object) => ({ ...prevState, [field.field_symbol]: field.value }))
    );
  }, []);

  useEffect(() => {
    setIsVisible(componentParams?.isEditorInitialVisible);
  }, [componentParams?.isEditorInitialVisible]);

  const handleClose = () => {
    setIsVisible(false), componentParams?.fnCallback(false);
  };

  const renderContent = () => {
    return fields.map((field) => {
      if (field.type === 'select') {
        return <DynamicSelect field={field} setRequestState={setRequestState} />;
      }

      if (field.type === 'decimal') {
        return <DynamicDecimal field={field} setRequestState={setRequestState} />;
      }

      return null;
    });
  };

  if (isVisible) {
    return (
      <Modal title={title} onClose={() => handleClose()}>
        <div className="content">{renderContent()}</div>

        <div className="actionWrapper">
          {actions.map((action, i) => (
            <Action
              key={i}
              action={action}
              handleClose={() => handleClose()}
              requestParams={requestParams}
              requestState={requestState}
              componentParams={componentParams}
            />
          ))}
        </div>
      </Modal>
    );
  }

  return null;
};

export default EditPanel;
