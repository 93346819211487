// sekcja płatnikiem i odbiorcą

import React, { FC, useEffect, useState } from 'react';
import classnames from 'classnames';
import { Trans } from 'react-i18next';

import { usePostCartDropshippingEnable, usePostCartDropshippingDisable } from 'api/endpoints';
import {
  ICartMainData,
  ICartMainDataUpdateRequest,
  ICommandResponseErrorField,
  ICheckoutStep
} from 'api/types';
import { ReceiverForm } from 'components/containers';
import { Checkbox } from 'components/controls';

import styles from 'theme/pages/Checkout/components/Payer/Payer.module.scss';

// typ danych wejściowych
interface IProps {
  cartId: number;
  customer: ICartMainData['customer'];
  receiver: ICartMainData['receiver'];
  dropShipping?: ICartMainData['dropshipping'];
  accessToUseDropshipping?: ICartMainData['enable_dropshipping'];
  cashOnDelivery?: ICartMainData['cash_on_delivery_value'];
  deliveryPoint?: ICartMainData['receiver_delivery_point_summary'];
  currency?: string;
  refetchCartMainData?: () => void;
  updateCartMainData: (data: Partial<ICartMainDataUpdateRequest>) => void;
  setCheckoutStep: (step: ICheckoutStep) => void;
  isSummary?: boolean;
  showInformationToPay?: boolean;
  error?: ICommandResponseErrorField;
}

const Payer: FC<IProps> = ({
  cartId,
  customer,
  receiver,
  dropShipping,
  showInformationToPay,
  refetchCartMainData,
  setCheckoutStep,
  isSummary,
  cashOnDelivery,
  deliveryPoint,
  currency,
  accessToUseDropshipping
}) => {
  const [dropshippingStatus, setDropshippingStatus] = useState<'ENABLED' | 'DISABLED' | undefined>(
    undefined
  );
  //obsługa dropshippingu
  const { mutate: enableDropshipping } = usePostCartDropshippingEnable(cartId, {
    onSuccess: () => {
      setDropshippingStatus('ENABLED');
      refetchCartMainData?.();
    }
  });

  //obsługa dropshippingu
  const { mutate: disableDropshipping } = usePostCartDropshippingDisable(cartId, {
    onSuccess: () => {
      setDropshippingStatus('DISABLED');
      refetchCartMainData?.();
    }
  });

  useEffect(() => {
    refetchCartMainData?.();
  }, []);

  const handleDropshipping = () => {
    dropShipping ? disableDropshipping() : enableDropshipping();
  };

  return (
    <>
      <div
        className={classnames(
          styles.componentWrapper,
          'StylePath-Pages-Checkout-Components-Payer',
          isSummary && styles.isSummary
        )}>
        <div className={styles.column}>
          <div className={styles.section}>
            <div className={styles.title}>
              <Trans>Dane do faktury</Trans>
            </div>
            {customer ? (
              <div className={classnames(styles.customer, styles.invoice)}>
                <div>
                  {customer.name && (
                    <>
                      {customer.name}
                      <br />
                    </>
                  )}
                  {customer.address.street && (
                    <>
                      {customer.address.street}
                      <br />
                    </>
                  )}
                  {(customer.address.postal_code || customer.address.city) && (
                    <>
                      {customer.address.city} {customer.address.postal_code}
                      <br />
                    </>
                  )}
                  {customer.address.country && (
                    <>
                      {customer.address.country}
                      <br />
                    </>
                  )}
                </div>
                <div>
                  {customer.tax_number && (
                    <>
                      <span className={styles.label}>
                        <Trans>NIP</Trans>:&nbsp;<span>{customer.tax_number}</span>
                      </span>
                    </>
                  )}

                  {customer.email && (
                    <>
                      <span className={styles.label}>
                        <Trans>E-mail</Trans>:&nbsp;<span>{customer.email}</span>
                      </span>
                    </>
                  )}
                  {customer.phone && (
                    <>
                      <span className={styles.label}>
                        <Trans>Telefon</Trans>:&nbsp;<span>{customer.phone}</span>
                      </span>
                    </>
                  )}
                </div>
              </div>
            ) : (
              '-'
            )}
          </div>
        </div>

        {!isSummary ? (
          <div className={styles.column}>
            <div className={styles.section}>
              <div className={styles.title}>
                <Trans>Dane do wysyłki</Trans>
                {/* jeśli is_enable_dropshipping zwraca false to ukrywać checkbox */}
                {accessToUseDropshipping === false ? (
                  <div />
                ) : (
                  <div>
                    <span>
                      <Trans>Dropshipping</Trans>:{` `}
                    </span>
                    <Checkbox checked={!!dropShipping} onClick={() => handleDropshipping()} />
                  </div>
                )}
              </div>
              <ReceiverForm
                id={cartId}
                receiver={receiver}
                isDropShipping={dropShipping}
                setCheckoutStep={setCheckoutStep}
                dropshippingStatus={dropshippingStatus}
              />
            </div>
          </div>
        ) : (
          <div className={styles.column}>
            {receiver && (
              <div className={styles.section}>
                <div className={styles.title}>
                  <Trans>Dane odbiorcy</Trans>
                  <button onClick={() => setCheckoutStep('ADDRESS')}>
                    <Trans>Zmień</Trans>
                  </button>
                </div>
                <div className={styles.receiver}>
                  <div>
                    <div>
                      {receiver.first_name} {receiver.last_name}
                    </div>
                    <div>
                      {receiver.address.street} {receiver.address.building}{' '}
                      {receiver.address.apartment}
                    </div>
                    <div>
                      {receiver.address.city} {receiver.address.postal_code}
                    </div>
                  </div>
                  <div>
                    <div>
                      <span className={styles.label}>
                        <Trans>E-mail</Trans>:&nbsp;<span>{receiver.email}</span>
                      </span>
                    </div>
                    <div>
                      <span className={styles.label}>
                        <Trans>Telefon</Trans>:&nbsp;<span>{receiver.phone}</span>
                      </span>
                    </div>
                  </div>
                </div>
                {cashOnDelivery && (
                  <div>
                    <div className={classnames(styles.title, styles.cashOnDelivery)}>
                      <span>
                        <Trans>Wartość pobrania</Trans>: {cashOnDelivery} {currency}
                      </span>
                      {showInformationToPay && (
                        <button onClick={() => setCheckoutStep('BILLING')}>
                          <Trans>Zmień</Trans>
                        </button>
                      )}
                    </div>
                  </div>
                )}
                {deliveryPoint && (
                  <div>
                    <div className={classnames(styles.title, styles.cashOnDelivery)}>
                      <span>
                        <Trans>Wybrany punkt odbioru</Trans>: {deliveryPoint.symbol}{' '}
                        {deliveryPoint.address.postal_code} {deliveryPoint.address.building}{' '}
                        {deliveryPoint.address.apartment} {deliveryPoint.address.city}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Payer;
